@use "../../App.scss" as *;
@use "../../styles/partials/_colors.scss" as *;
@use "../../styles/partials/_fonts.scss" as *;
@use "../../styles/partials/_variables.scss" as *;
@use "../../styles/partials/_mixins.scss" as *;
@use "../../styles/partials/_function.scss" as *;

.hackathon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: toRem(16);
  margin: 0, toRem(16);
  width: 100%;

  @include tablet {
    flex-direction: row;
    margin-right: toRem(36);
    margin-left: toRem(36);
  }
}

.hackathon {
  border-radius: 35px;
  border: 4px solid $divider;
  width: toRem(445);
  height: toRem(375);

  @include tablet {
    width: toRem(545);
    height: toRem(460);
    margin-right: toRem(30);
  }

  &-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: toRem(20);
    margin: 0, toRem(16);
    width: 90%;

    @include tablet {
      align-items: flex-start;
      line-height: toRem(30);
      width: 40vw;
    }
  }

  &-title {
    font-size: toRem(22);
    font-weight: $semibold;
    padding-bottom: toRem(10);
    margin-top: toRem(16);
    text-align: center;

    @include tablet {
      font-size: toRem(34);
      padding-bottom: toRem(20);
    }
  }

  &-tech {
    font-size: toRem(14);
    padding-bottom: toRem(8);

    @include tablet {
      font-size: toRem(18);
      padding-bottom: toRem(18);
    }
  }

  &-description {
    font-size: toRem(14);
    padding-bottom: toRem(8);
    width: toRem(320);

    @include tablet {
      font-size: toRem(18);
    }
  }

  &-github {
    width: toRem(50);

    cursor: pointer;

    @include tablet {
      width: toRem(50);
      margin-top: toRem(18);
      margin-left: toRem(200);
    }

    &:hover {
      background-color: $footer;
      border-radius: 50%;
      border: 1.5px solid $divider;
    }
  }
}
