@use "../../App.scss" as *;
@use "../../styles/partials/_colors.scss" as *;
@use "../../styles/partials/_fonts.scss" as *;
@use "../../styles/partials/_variables.scss" as *;
@use "../../styles/partials/_mixins.scss" as *;
@use "../../styles/partials/_function.scss" as *;

.project {
  margin-left: toRem(16);
  margin-right: toRem(16);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;

  @include tablet {
    margin-left: toRem(32);
    padding-top: toRem(55);
    margin-top: toRem(150);
  }

  &-title {
    font-size: toRem(24);
    font-weight: $semibold;
    padding-bottom: toRem(10);

    @include tablet {
      font-size: toRem(36);
      padding-bottom: toRem(60);
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-pages {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &-buttons {
    margin: 0 auto;
    margin-top: toRem(20);
  }
}

.back {
  color: red;
}
