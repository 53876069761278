@use "../../App.scss" as *;
@use "../../styles/partials/_colors.scss" as *;
@use "../../styles/partials/_fonts.scss" as *;
@use "../../styles/partials/_variables.scss" as *;
@use "../../styles/partials/_mixins.scss" as *;
@use "../../styles/partials/_function.scss" as *;

.footer {
  display: flex;
  width: 100%;
  padding-left: toRem(32);
  align-items: center;
  color: $footer;
  background-color: $footer;
  padding-top: toRem(20);
  padding-bottom: toRem(16);
  border-top: 3px solid $divider;

  &-link {
    text-decoration: none;
    color: $text;

    &:hover {
      text-decoration: underline;
    }
  }

  &-link-text {
    font-size: toRem(12);
    font-weight: $semibold;

    @include tablet {
      font-size: toRem(16);
      font-weight: $medium;
    }
  }

  &-text-container {
    display: flex;
    flex-direction: column;
    color: $text;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include tablet {
      width: 85%;
      justify-content: center;
    }
  }

  &-site {
    font-size: toRem(12);
    @include tablet {
      font-size: toRem(16);
    }
  }

  &-year {
    font-size: toRem(10);
    @include tablet {
      font-size: toRem(14);
      padding-bottom: toRem(20);
    }
  }
}
