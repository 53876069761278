@use "./styles/partials/_variables.scss" as *;
@use "./styles/partials/_fonts.scss" as *;
@use "./styles/partials/_mixins.scss" as *;
@use "./styles/partials/_reset.scss" as *;
@use "./styles/partials/_colors.scss" as *;
@use "./styles/partials/_function.scss" as *;

body,
html {
  font-family: $main;
  font-weight: $regular;
  background-color: $bg;
  color: $text;
  height: 100%;
}

.bold {
  font-weight: $semibold;
  color: $divider;
}
