//navbar
$navbar: #116466;
$activetab: #2c3531;

//text color
$text: #f9f6f0;

//divider
$divider: #ffcb9a;

//contact page

//footer
$footer: #116466;

//background
$bg: #36454f;
