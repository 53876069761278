@use "../../App.scss" as *;
@use "../../styles/partials/_colors.scss" as *;
@use "../../styles/partials/_fonts.scss" as *;
@use "../../styles/partials/_variables.scss" as *;
@use "../../styles/partials/_mixins.scss" as *;
@use "../../styles/partials/_function.scss" as *;

.contact {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  margin-top: toRem(50);

  @include tablet {
    flex-direction: row;
    height: 90vh;
    margin-top: 0;
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      align-items: flex-start;
      margin-right: toRem(40);
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: toRem(16);
    background-color: $navbar;
    border-radius: 4px;
    width: toRem(250);
    height: toRem(37);

    @include tablet {
      margin-bottom: toRem(32);
      padding: toRem(4) toRem(4);
      width: toRem(330);
      height: toRem(56);
    }

    &:hover {
      border: 2px solid $divider;
    }
  }

  &-name {
    font-size: toRem(26);
    font-weight: $semibold;
    padding-bottom: toRem(10);
    @include tablet {
      font-size: toRem(40);
      padding-bottom: toRem(32);
    }
  }

  &-email {
    width: toRem(50);
    padding-left: toRem(16);
  }

  &-email-text {
    font-size: toRem(14);
    margin-left: toRem(10);

    @include tablet {
      font-size: toRem(18);
      margin-left: toRem(16);
    }
  }

  &-github {
    width: toRem(50);
    padding-left: toRem(16);
    cursor: pointer;
  }

  &-github-text {
    font-size: toRem(14);
    margin-left: toRem(10);

    @include tablet {
      font-size: toRem(18);
      margin-left: toRem(16);
    }
  }

  &-linkedin {
    width: toRem(45);
    height: toRem(30);
    padding-left: toRem(16);
    cursor: pointer;
  }

  &-linkedin-text {
    font-size: toRem(14);
    margin-left: toRem(10);
    @include tablet {
      font-size: toRem(18);
      margin-left: toRem(16);
    }
  }

  &-location {
    width: toRem(50);
    padding-left: toRem(16);
  }

  &-location-text {
    font-size: toRem(14);
    margin-left: toRem(10);

    @include tablet {
      font-size: toRem(18);
      margin-left: toRem(16);
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    width: toRem(250);

    @include tablet {
      width: toRem(350);
      border-left: solid 2px $divider;
      padding-left: toRem(40);
      margin-top: toRem(32);
    }
  }

  &-form-section {
    display: flex;
    flex-direction: column;
    margin-bottom: toRem(8);

    @include tablet {
      margin-bottom: toRem(24);
    }
  }

  &-input {
    height: toRem(24);
    border-radius: 5px;
    border: none;
    margin-top: toRem(4);
    background-color: $text;

    @include tablet {
      height: toRem(30);
      border-radius: 5px;
      margin-top: toRem(4);
    }

    &:focus {
      border: 3px solid $divider;
      outline: none;
    }
  }

  &-button {
    border: 2px solid $divider;
    background-color: transparent;
    color: $text;
    font-family: $main;
    font-size: toRem(18);
    font-weight: $semibold;
    cursor: pointer;
    width: toRem(100);
    border-radius: 4px;
    padding-top: toRem(3);
    padding-bottom: toRem(3);
    margin: 0 auto;
    margin-top: toRem(18);
    margin-bottom: toRem(50);

    @include tablet {
      margin-bottom: 0;
    }

    &:hover {
      background-color: $footer;
    }
  }
}

label {
  font-size: toRem(16);
}

textarea {
  height: toRem(100);
  resize: none;
  border-radius: 5px;
  background-color: $text;
  margin-top: 0.75rem;

  @include tablet {
    margin-top: 0;
    height: toRem(125);
  }

  &:focus {
    border: 3px solid $divider;
    outline: none;
  }
}
