@use "../../App.scss" as *;
@use "../../styles/partials/_colors.scss" as *;
@use "../../styles/partials/_fonts.scss" as *;
@use "../../styles/partials/_variables.scss" as *;
@use "../../styles/partials/_mixins.scss" as *;
@use "../../styles/partials/_function.scss" as *;

.brainflix-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: toRem(16);
  margin: 0, toRem(16);
  width: 100%;

  @include tablet {
    flex-direction: row;
    margin-right: toRem(36);
    margin-left: toRem(36);
  }
}

.brainflix {
  border-radius: 35px;
  border: 4px solid $divider;
  width: toRem(435);
  height: toRem(365);

  @include tablet {
    border-radius: 35px;
    margin-right: toRem(30);
    width: toRem(590);
    height: toRem(495);
  }

  &-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: toRem(20);
    margin: 0, toRem(16);
    width: 90%;

    @include tablet {
      align-items: flex-start;
      line-height: toRem(30);
      width: 40vw;
    }
  }

  &-title {
    font-size: toRem(22);
    font-weight: $semibold;
    padding-bottom: toRem(8);
    margin-top: toRem(16);
    text-align: center;

    @include tablet {
      font-size: toRem(34);
      padding-bottom: toRem(20);
    }
  }

  &-tech {
    @include mobile {
      font-size: toRem(14);
      padding-bottom: toRem(8);
    }
    font-size: toRem(18);
    padding-bottom: toRem(18);
  }

  &-description {
    font-size: toRem(14);
    width: toRem(375);

    @include tablet {
      font-size: toRem(18);
    }
  }

  &-github {
    width: toRem(50);
    margin-top: toRem(8);
    cursor: pointer;

    @include tablet {
      margin-top: toRem(18);
      margin-left: toRem(200);
    }

    &:hover {
      background-color: $footer;
      border-radius: 50%;
      border: 1.5px solid $divider;
    }
  }
}
