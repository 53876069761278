@use "../../App.scss" as *;
@use "../../styles/partials/_colors.scss" as *;
@use "../../styles/partials/_fonts.scss" as *;
@use "../../styles/partials/_variables.scss" as *;
@use "../../styles/partials/_mixins.scss" as *;
@use "../../styles/partials/_function.scss" as *;

.navbar {
  background-color: $navbar;
  border-bottom: 3px solid $divider;
  padding: toRem(20) toRem(20);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: toRem(80);
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;

  @include tablet {
    padding: toRem(20) toRem(40);
    align-items: flex-end;
    flex-direction: row;
  }

  &__logo {
    font-size: toRem(15);
    font-weight: $semibold;
    padding-bottom: toRem(8);

    @include tablet {
      font-size: toRem(28);
      padding-bottom: 0;
    }
  }

  &__right {
    display: flex;
    list-style: none;
    align-items: center;
    font-size: toRem(15);

    @include tablet {
      font-size: toRem(24);
    }
  }

  &__item {
    margin-left: toRem(20);
    font-weight: $semibold;
    cursor: pointer;
  }

  &__link {
    text-decoration: none;
    color: $text;

    &:hover {
      color: #ffcb9a;
    }
  }

  &__logo-link {
    text-decoration: none;
    color: $text;
    cursor: pointer;
  }
}
